<template>
  <div class="page-head">
    <h1>Nemovitosti k pronájmu</h1>
    <div class="page-head__actions">
      <Button @click="$router.push({ name: 'rentalHomes-new' })" label="Přidat nový" icon="pi pi-plus" />
    </div>
  </div>

  <div class="box">
    <DataTable :value="homes" dataKey="id" :rowHover="true" :loading="loading">
      <template #loading> Načítám apartmány... </template>
      <template #empty>
        Žádný apartmán.
      </template>

      <Column field="mainImage" class="image__thumb" style="width: 70px;">
        <template #body="{ data }">
          <router-link :to="{ name: 'rentalHomes-detail', params: { id: data.id } }">
            <img v-if="data.mainImage" :src="data.mainImage.file.thumb" />
          </router-link>
        </template>
      </Column>

      <Column field="name" header="Jméno">
        <template #body="{ data }">
          <router-link :to="{ name: 'rentalHomes-detail', params: { id: data.id } }">
            {{ data.name.cs }}
          </router-link>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { Homes } from '@/services/repositories/RentalHomes';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

export default {
  data() {
    return {
      homes: [],
      loading: true
    };
  },
  mounted() {
    Homes.getAll().then(data => {
      this.homes = data;
      this.loading = false;
    });
  },
  components: {
    DataTable,
    Button,
    Column
  }
};
</script>

<style lang="scss" scoped>
.image {
  &__thumb {
    img {
      --size: 50px;
      width: var(--size);
      height: var(--size);
      object-fit: cover;
    }
  }
}
</style>
