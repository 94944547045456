<template>
  <div class="user-menu">
    <router-link :to="{ name: 'profile' }" class="item">
      <div class="item__icon">
        <Icon name="user" />
      </div>
      <div class="item__label">Můj profil</div>
    </router-link>

    <button class="item" @click="logout">
      <div class="item__icon">
        <Icon name="arrowRightFromBracket" />
      </div>
      <div class="item__label">Odhlásit se</div>
    </button>
  </div>
</template>

<script>
import { User } from '@/services/User';
import Icon from '@/components/icons/Icon';

export default {
  data() {
    return {
      user: this.$store.state.userStore.user,
    };
  },
  methods: {
    logout(e) {
      e.preventDefault();
      User.logout();
    },
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.user-menu {
  .item {
    display: flex;
    align-items: center;
    gap: 13px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-bottom: 10px;
    min-width: 200px;
    &__icon {
      --size: 13px;
      width: var(--size);
      height: var(--size);
    }
    &__label {
      font-weight: 300;
      font-size: 13px;
    }
  }
}
</style>
