<template>
  <Dialog
    header="Bod na mapě"
    v-model:visible="isDialogOpen"
    :modal="true"
    class="dialog"
    :closable="true"
    :dismissableMask="true"
    style="width: 95%;max-width: 1000px"
  >
    <div class="map-wrapper">
      <l-map :zoom="zoom" :center="center" @click="updateLocation" v-if="showMap">
        <l-tile-layer :url="currentTilesProvider.url" :subdomains="currentTilesProvider.subdomains" />

        <template v-if="coordinates">
          <l-marker :lat-lng="coordinates">
            <l-tooltip>
              {{ coordinates }}
            </l-tooltip>
          </l-marker>
        </template>
      </l-map>

      <div class="map-wrapper__controls">
        <Dropdown v-model="currentTilesProvider" :options="tiles" optionLabel="name" />
      </div>
    </div>

    <template #footer>
      <Button
        label="Storno"
        icon="pi pi-times"
        @click="isDialogOpen = false"
        class="p-button-text"
      />
      <Button label="Uložit" icon="pi pi-check" @click="save" />
    </template>
  </Dialog>

  <div class="value" @click="isDialogOpen = true">
    <span v-if="coordinates">
      {{ coordinates }}
    </span>
    <span v-else>
      <Button label="Zvolit" class="p-button-secondary" />
    </span>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import { LMap, LTileLayer, LMarker, LTooltip } from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  emits: ['point-picked'],
  props: {
    value: String,
    name: String
  },
  data() {
    return {
      tiles: [
        {
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          name: 'Open Street Map',
          subdomains: 'abc'
        },
        {
          url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
          name: 'Google Maps satelitní',
          subdomains: ['mt0','mt1','mt2','mt3']
        }
      ],
      currentTilesProvider: null,
      isDialogOpen: false,
      zoom: 13,
      coordinates: null,
      showMap: false
    };
  },
  computed: {
    center() {
      if (this.coordinates) {
        return this.coordinates;
      } else {
        return ['25.354847', '55.457611'];
      }
    }
  },
  mounted() {
    if (this.value) {
      this.coordinates = this.value.split(' ');
    }

    this.currentTilesProvider = this.tiles[0];
  },
  watch: {
    currentTilesProvider() {
      this.refreshMap();
    }
  },
  methods: {
    updateLocation(e) {
      if (e.latlng) {
        this.coordinates = e.latlng;
      }
    },
    refreshMap() {
      this.showMap = false;
      setTimeout(() => {
        this.showMap = true;
      }, 50);
    },
    save() {
      if (!this.coordinates) {
        window.eventBus.emit('error-toast', 'Nebyl vybrán žádný bod!');
      } else {
        this.isDialogOpen = false;

        this.$emit('point-picked', {
          name: this.name,
          value: this.coordinates.lat + ' ' + this.coordinates.lng
        });
      }
    }
  },
  components: {
    Dialog,
    Button,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    Dropdown
  }
};
</script>

<style lang="scss">
.leaflet-control-attribution {
  display: none;
}
</style>

<style lang="scss" scoped>
.map-wrapper {
  width: 100%;
  height: 500px;
  border-radius: 5px;
  position: relative;

  &__controls {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 999;
  }
}
.leaflet-grab {
  cursor: crosshair;
}
</style>
