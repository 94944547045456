<template>
  <div class="logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 188 78.8"
    >
      <g>
        <path
          d="M24.6,63.3c-0.2,0.4-0.5,0.8-0.8,1.1c-0.6,0.6-1.4,0.8-2.3,0.8c-1.7,0-3.4-1-3.4-3.6c0-2.1,1.2-3.6,3.3-3.6
		c1.3,0,2.2,0.7,2.6,1.2c0.4,0.6,0.7,1.7,0.6,2.6h-5.3c0,1.4,1,2.3,2.2,2.3c0.6,0,1-0.2,1.4-0.5c0.3-0.3,0.6-0.6,0.7-0.9L24.6,63.3
		L24.6,63.3z M23.5,60.8c-0.2-1.1-1-1.8-2-1.8s-1.8,0.8-2,1.8C19.4,60.8,23.5,60.8,23.5,60.8z"
        />
        <path d="M28.2,58.2h1.2V59c0.2-0.3,0.8-1,2-1c0.8,0,1.6,0.3,2,0.9c0.3,0.5,0.4,1.1,0.4,1.8V65h-1.2v-4.2c0-0.4,0-0.9-0.3-1.2
		c-0.2-0.3-0.6-0.5-1.1-0.5c-0.4,0-0.9,0.1-1.2,0.5c-0.5,0.5-0.5,1.4-0.5,1.9V65h-1.2V58.2L28.2,58.2z" />
        <polygon points="37.9,58.2 39.9,62.8 42,58.2 43.3,58.2 39.9,65.5 36.6,58.2 	" />
        <path d="M46.8,54.7c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8S46,56,46,55.6S46.3,54.7,46.8,54.7 M47.4,65h-1.2v-6.9h1.2V65z" />
        <path
          d="M54.1,59.7c-0.2-0.4-0.5-0.7-1-0.7s-0.8,0.2-0.8,0.8c0,0.5,0.3,0.6,1.1,1c1,0.4,1.4,0.7,1.7,1.1c0.3,0.4,0.4,0.7,0.4,1.1
		c0,1.4-1,2.2-2.4,2.2c-0.3,0-1.8,0-2.4-1.8l1-0.4c0.1,0.4,0.5,1.1,1.4,1.1c0.8,0,1.1-0.6,1.1-1.1c0-0.6-0.5-0.9-1.1-1.1
		c-0.8-0.4-1.3-0.6-1.7-1c-0.3-0.3-0.3-0.7-0.3-1c0-1.2,0.8-1.9,2-1.9c0.4,0,1.3,0.1,1.9,1.2L54.1,59.7L54.1,59.7z"
        />
        <path d="M59.3,54.7c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8S58.9,54.7,59.3,54.7 M59.9,65h-1.2v-6.9h1.2V65z" />
        <path d="M63.4,61.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6s-1.6,3.6-3.6,3.6S63.4,63.7,63.4,61.6 M64.6,61.6c0,1.7,1.2,2.5,2.4,2.5
		s2.4-0.9,2.4-2.5S68.3,59,67,59S64.6,59.9,64.6,61.6" />
        <path d="M74.1,58.2h1.2V59c0.2-0.3,0.8-1,2-1c0.8,0,1.6,0.3,2,0.9c0.4,0.5,0.4,1.1,0.4,1.8V65h-1.2v-4.2c0-0.4,0-0.9-0.3-1.2
		c-0.2-0.3-0.6-0.5-1.1-0.5c-0.4,0-0.9,0.1-1.2,0.5c-0.5,0.5-0.5,1.4-0.5,1.9V65h-1.2L74.1,58.2L74.1,58.2z" />
        <polygon points="91.2,64.4 88,58.2 89.4,58.2 91.8,63.1 94,58.2 95.4,58.2 90.3,68.8 89,68.8 	" />
        <path d="M97.8,61.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6s-1.6,3.6-3.6,3.6S97.8,63.7,97.8,61.6 M99,61.6c0,1.7,1.2,2.5,2.4,2.5
		s2.4-0.9,2.4-2.5s-1.1-2.6-2.4-2.6S99,59.9,99,61.6" />
        <path d="M109.7,62.2c0,0.5,0,1,0.3,1.5c0.2,0.2,0.5,0.5,1.2,0.5s1-0.3,1.2-0.5c0.3-0.4,0.3-1,0.3-1.5v-4h1.2v4.1
		c0,0.8-0.1,1.5-0.6,2.1c-0.6,0.6-1.4,0.9-2.1,0.9s-1.5-0.2-2.1-0.9c-0.5-0.6-0.6-1.2-0.6-2.1v-4.1h1.2L109.7,62.2L109.7,62.2z" />
        <path d="M117.9,58.2h1.2v0.7c0.1-0.2,0.6-0.9,1.6-0.9c0.4,0,0.8,0.1,1.1,0.3l-0.6,1.1c-0.3-0.2-0.5-0.2-0.7-0.2
		c-0.5,0-0.8,0.2-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.9V65h-1.2V58.2L117.9,58.2z" />
        <path d="M131.1,59.2V65h-1.2v-5.8h-0.7v-1.1h0.7v-3c0-0.6,0.1-1.4,0.7-1.9c0.3-0.3,0.8-0.4,1.4-0.4c0.4,0,0.7,0.1,0.8,0.1v1.1
		c-0.2-0.1-0.5-0.1-0.8-0.1c-0.3,0-0.6,0.1-0.8,0.4c-0.1,0.2-0.1,0.6-0.1,1v2.9h1.7v1.1L131.1,59.2L131.1,59.2z" />
        <path d="M136.7,62.2c0,0.5,0,1,0.3,1.5c0.2,0.2,0.5,0.5,1.2,0.5s1-0.3,1.2-0.5c0.3-0.4,0.3-1,0.3-1.5v-4h1.2v4.1
		c0,0.8-0.1,1.5-0.6,2.1c-0.6,0.6-1.4,0.9-2.1,0.9s-1.5-0.2-2.1-0.9c-0.5-0.6-0.6-1.2-0.6-2.1v-4.1h1.2L136.7,62.2L136.7,62.2z" />
        <polygon points="146.1,59.2 146.1,65 144.9,65 144.9,59.2 144.2,59.2 144.2,58.2 144.9,58.2 144.9,55.7 146.1,55.7 146.1,58.2
		147.3,58.2 147.3,59.2 	" />
        <path d="M151.2,62.2c0,0.5,0,1,0.3,1.5c0.2,0.2,0.5,0.5,1.2,0.5s1-0.3,1.2-0.5c0.3-0.4,0.3-1,0.3-1.5v-4h1.2v4.1
		c0,0.8-0.1,1.5-0.6,2.1c-0.6,0.6-1.4,0.9-2.1,0.9s-1.5-0.2-2.1-0.9c-0.5-0.6-0.6-1.2-0.6-2.1v-4.1h1.2L151.2,62.2L151.2,62.2z" />
        <path d="M159.4,58.2h1.2v0.7c0.1-0.2,0.6-0.9,1.6-0.9c0.4,0,0.8,0.1,1.1,0.3l-0.6,1.1c-0.3-0.2-0.5-0.2-0.7-0.2
		c-0.5,0-0.8,0.2-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.9V65h-1.2V58.2L159.4,58.2z" />
        <path
          d="M171.6,63.3c-0.2,0.4-0.5,0.8-0.8,1.1c-0.6,0.6-1.4,0.8-2.3,0.8c-1.6,0-3.4-1-3.4-3.6c0-2.1,1.2-3.6,3.3-3.6
		c1.3,0,2.2,0.7,2.6,1.2c0.4,0.6,0.7,1.7,0.6,2.6h-5.3c0,1.4,1,2.3,2.2,2.3c0.6,0,1-0.2,1.4-0.5c0.3-0.3,0.6-0.6,0.7-0.9L171.6,63.3
		L171.6,63.3z M170.4,60.8c-0.2-1.1-1-1.8-2-1.8s-1.8,0.8-2,1.8H170.4L170.4,60.8z"
        />
        <path
          d="M92.2,17c0,0.1-0.1,0.6-0.2,1.3s-0.3,1.4-0.4,1.9c-0.1,0.3-0.1,0.7-0.1,1.1s-0.1,0.8-0.2,1c-0.4,1.2-0.9,2.5-1.7,3.8
		s-1.7,2.7-2.7,4.1s-2.1,2.7-3.3,4c-1.2,1.3-2.4,2.5-3.6,3.5c-0.2,0.1-0.4,0.4-0.6,0.7c-0.2,0.3-0.4,0.6-0.7,0.8
		c-0.5,0.4-1.1,0.8-1.7,1.2c-0.6,0.4-1.2,0.7-1.7,1.1c-0.5,0.3-1.1,0.6-2,1.1c-0.9,0.5-1.7,0.7-2.2,0.7c-0.2,0-0.5,0-0.8-0.1
		c-0.3-0.1-0.6-0.1-0.8-0.1S69,43.1,69,43.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.3,0,0.6,0,0.8s0.1,0.6,0,0.8h-0.8c-0.2,0-0.5-0.2-0.9-0.6
		c-0.4-0.4-0.6-0.7-0.7-0.9c-0.2-0.5-0.5-1-0.8-1.7c-0.3-0.7-0.6-1.3-0.9-1.9c-0.3-0.6-0.6-1.2-0.8-1.6s-0.3-0.8-0.3-0.9
		c0-0.2,0.1-0.4,0.1-0.6s0.1-0.5,0.1-0.8h0.6c0-0.2,0.1-0.4,0.1-0.6v-1.2c0-0.5,0-1.3,0-2.3c0-1,0.1-2.3,0.2-3.9
		c0-0.3,0.1-0.9,0.2-1.6c0.1-0.8,0.3-1.6,0.5-2.6c0.2-0.9,0.4-1.9,0.6-2.8c0.2-1,0.4-1.8,0.7-2.5c0.4-1.2,0.7-2.4,1.1-3.7
		c0.4-1.3,0.8-2.5,1.3-3.8v-0.6c-0.8,0.3-1.6,0.7-2.2,1.3s-1.2,1.2-1.7,2c-0.1,0-0.2,0-0.3,0.1C64.9,14,64.7,14,64.5,14
		c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.4-0.3-0.7-0.5s-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.3-0.3-0.4c0-0.2,0-0.3,0.1-0.3s0.2,0,0.2-0.1
		c-0.1-0.4-0.1-0.9-0.1-1.4c0-0.5,0.1-0.9,0.1-1.4c1.2-0.8,2.6-1.5,4.2-2.1c1.6-0.7,3.1-1.3,4.5-1.9c0.3-0.1,0.5-0.5,0.7-1.2
		c0.2-0.6,0.3-1.2,0.4-1.7c0.1-0.3,0.3-0.7,0.7-1.2c0.3-0.5,0.7-0.8,1.1-1.2c0.3-0.1,0.5,0.1,0.7,0.5c0.2,0.9,0.3,1.6,0.5,2.1
		s0.1,1.1-0.2,1.7l0.3,0.3c1.9-0.1,3.8,0.1,5.8,0.6s3.9,1.3,5.5,2.4c1.6,1.1,3,2.4,4,4C91.8,13.1,92.3,14.9,92.2,17 M87.3,14.3
		c0-0.2,0-0.5,0-0.9c0-0.4-0.1-0.8-0.1-1.2c-0.1-0.4-0.2-0.8-0.3-1.1c-0.1-0.3-0.3-0.6-0.5-0.6c-0.3-0.1-0.7-0.3-1.2-0.5
		c-0.5-0.2-0.9-0.4-1.4-0.5c-0.5-0.2-0.9-0.3-1.2-0.4s-0.5-0.2-0.6-0.2h-1.6c-0.6,0-1.3,0-1.9,0.1c-0.7,0.1-1.3,0.1-1.8,0.2
		c-0.6,0.1-1,0.3-1.3,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.3-0.1,0.5c-0.6,2.3-1.2,4.7-1.6,7.1c-0.5,2.4-0.9,4.6-1.2,6.7
		c-0.2,0.8-0.4,1.6-0.6,2.5c-0.2,0.8-0.4,1.6-0.7,2.4c-0.1,0.5-0.3,1.1-0.5,2c-0.2,0.9-0.4,1.8-0.6,2.8c-0.2,1-0.4,1.9-0.5,2.8
		c-0.1,0.9-0.2,1.6-0.2,2c1.6-0.3,3.2-1.2,5.1-2.5c1.8-1.4,3.6-3.1,5.3-5.1s3.2-4.3,4.5-6.9c1.3-2.6,2.2-5.2,2.7-7.9
		c0-0.3,0.1-0.7,0.2-1.2S87.3,14.5,87.3,14.3"
        />
        <path
          d="M123.4,36.5c-0.1,0.1-0.1,0.2-0.3,0.3c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.3,0.4s-0.1,0.3,0.1,0.5c-0.6,0.4-1,0.8-1.4,1.2
		c-0.4,0.4-0.8,0.8-1.2,1.2c-0.4,0.4-0.9,0.8-1.4,1.3s-1.1,0.9-1.8,1.4c-0.5,0.4-0.8,0.6-1,0.6c-0.1,0.1-0.4,0.2-0.8,0.4
		c-0.4,0.2-0.9,0.4-1.5,0.5c-0.6,0.1-1,0.2-1.2,0.2c-0.6,0-1,0-1.4-0.1c-0.4-0.1-0.9-0.4-1.5-0.9c-0.1,0-0.3-0.1-0.6-0.1
		c-1.1-1.1-2-2.1-2.5-3.2c-0.5-1.1-0.8-2.2-1-3.3c-0.1-1.2-0.1-2.4,0.1-3.7c0.2-1.3,0.4-2.8,0.7-4.3c-0.4,0.5-0.8,1.1-1.2,1.8
		c-0.4,0.7-0.9,1.5-1.3,2.3c-0.5,0.8-0.9,1.6-1.4,2.4c-0.5,0.8-0.9,1.5-1.3,2c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2-0.1,0.5-0.2,0.6
		c-0.4,0.4-0.8,0.9-1.2,1.3s-0.7,0.9-1.1,1.4c-0.4,0.5-0.8,0.8-1.2,1.1c-0.4,0.3-0.9,0.4-1.5,0.4c-0.9,0-1.8-0.3-2.4-0.9
		s-1.2-1.3-1.6-2.1s-0.7-1.6-0.9-2.5c-0.2-0.8-0.3-1.5-0.3-2.1c0-0.1,0-0.3,0.1-0.8s0.2-0.9,0.3-1.4c0.1-0.5,0.3-1.1,0.5-1.6
		c0.2-0.6,0.4-1.1,0.6-1.5c0.7-1.9,1.5-3.7,2.3-5.6c0.9-1.9,1.6-3.7,2.2-5.4c0.3,0.1,0.7,0.3,1,0.7s0.7,0.8,1,1.3s0.5,1,0.7,1.5
		c0.2,0.5,0.3,0.9,0.3,1.2c0,0.1-0.1,0.7-0.4,1.6c-0.3,0.9-0.6,2-1.1,3.3c-0.4,1.3-0.9,2.6-1.3,4c-0.5,1.4-0.9,2.6-1.2,3.6v0.6h0.2
		c0.6-0.5,1.4-1.3,2.4-2.4s1.8-2,2.6-2.9c0.8-0.9,1.6-2,2.4-3.2s1.8-2.3,2.8-3.5c0.5-0.6,0.9-1.2,1.3-1.8c0.3-0.6,0.7-1.2,1-1.8
		c0.3-0.6,0.7-1,1.1-1.3c0.4-0.3,0.9-0.5,1.5-0.5c0.4,0.1,0.7,0.2,1,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.2,0.4,0.3
		c0.2,0.5,0.4,1.2,0.6,1.9s0.2,1.2,0.2,1.5c0,0.1-0.1,0.4-0.2,1.1c-0.1,0.6-0.3,1.3-0.4,2.1c-0.2,0.8-0.3,1.6-0.6,2.5
		c-0.2,0.8-0.4,1.6-0.5,2.1c-0.2,1.3-0.4,2.7-0.6,4.3c-0.2,1.6-0.3,3.1-0.4,4.7c0.1,0.2,0.3,0.3,0.4,0.3h0.6c0.6,0,1.3-0.2,2.1-0.5
		s1.8-0.7,2.7-1.1s1.7-0.8,2.4-1.2c0.7-0.4,1.2-0.6,1.4-0.7c0.3-0.1,0.8-0.4,1.4-0.7C122.6,36.5,123.1,36.4,123.4,36.5"
        />
        <path
          d="M141.5,25.6c0,0.1,0,0.4-0.1,0.8s-0.2,0.9-0.3,1.5s-0.2,1.1-0.4,1.7c-0.2,0.6-0.3,1.1-0.4,1.5c-0.3,0.7-0.7,1.6-1.3,2.7
		c-0.6,1.1-1.1,2-1.6,2.9c-0.1,0.1-0.4,0.6-1,1.4c-0.6,0.8-1.3,1.7-2,2.6s-1.5,1.7-2.2,2.5c-0.7,0.7-1.2,1.1-1.5,1.1h-1.2
		c-0.2,0-0.5-0.2-0.9-0.6c-0.4-0.4-0.8-0.9-1.1-1.4c-0.1-0.3-0.3-0.6-0.4-1s-0.3-0.8-0.4-1.1s-0.2-0.7-0.3-1
		c-0.1-0.3-0.1-0.6-0.1-0.7c0-0.2,0.1-0.5,0.4-0.8c0.3-0.3,0.5-0.6,0.7-0.9c0,0.6,0.1,1.2,0.3,1.8l-0.1,0.1c0,0,0.1,0.1,0.3,0.1
		c0.1,0,0.4-0.2,0.7-0.5s0.7-0.7,1-1.2c0.3-0.4,0.7-0.9,1-1.3c0.3-0.4,0.6-0.8,0.7-1c1.6-2.1,2.9-3.9,4.1-5.6s2-3.3,2.6-5.1
		c-0.1-0.1-0.2-0.1-0.2-0.1h-0.4c-1,0-2,0.3-2.9,0.9s-1.8,1.3-2.6,2s-1.5,1.5-2.1,2.2s-1.1,1.2-1.4,1.4c-0.4,0.7-0.8,1.5-1.3,2.4
		c-0.5,0.8-1,1.6-1.3,2.4c-0.1,0.1,0,0.2,0,0.4c0.1,0.2,0.1,0.4,0,0.6c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.3-0.4,0.6
		c-0.1,0.2-0.2,0.5-0.3,0.9c-0.1,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.4,0.8-0.6,1.1c-0.2,0.3-0.6,0.5-1,0.5c-0.2,0-0.4-0.1-0.7-0.3
		c-0.3-0.2-0.6-0.5-0.8-0.8s-0.5-0.6-0.7-0.9s-0.3-0.4-0.3-0.5c0-0.3-0.1-0.6-0.2-1c-0.1-0.4-0.1-0.8-0.2-1.1
		c-0.1-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.1-0.5-0.1-0.6c0-0.8,0.1-1.7,0.4-2.9c0.2-1.1,0.6-2.3,0.9-3.6c0.4-1.3,0.7-2.6,1.1-3.8
		c0.4-1.3,0.7-2.4,1.1-3.4c0.6-2,1.4-4.2,2.2-6.5c0.8-2.3,1.5-4.6,2-6.9c0.1-0.4,0.1-1,0-1.8s-0.1-1.7,0-2.5h1.2
		c0.6,0,1.2,0.2,1.6,0.7s0.7,0.9,1,1.5s0.4,1.2,0.5,1.7s0.1,0.9,0.1,1.1c0,0.2-0.1,0.7-0.2,1.3c-0.1,0.6-0.2,1.1-0.3,1.5
		c-0.2,1.5-0.5,2.9-0.9,4.3c-0.4,1.4-0.8,2.9-1.1,4.3c-0.2,0.8-0.5,1.6-0.7,2.4c-0.2,0.8-0.5,1.7-0.7,2.7c1.4-1.2,2.9-2.2,4.2-3
		c1.4-0.8,2.8-1.2,4.2-1.2c0.2,0,0.6,0.1,1.2,0.2c0.6,0.2,1,0.3,1.4,0.4c0.9,0.4,1.6,1.1,2.1,1.9S141.5,24.7,141.5,25.6"
        />
        <path
          d="M171.2,36.4c-0.1,0.3-0.3,0.6-0.6,0.8s-0.4,0.4-0.6,0.6c-0.1,0.1-0.2,0.3-0.2,0.6s-0.1,0.4-0.1,0.6
		c-0.3,0.4-0.6,0.9-1.1,1.5c-0.5,0.6-1,1.2-1.5,1.8c-0.1,0.1-0.4,0.3-0.8,0.5c-0.4,0.2-0.8,0.4-1.3,0.5c-0.5,0.2-1,0.3-1.5,0.4
		c-0.5,0.1-1,0.2-1.4,0.2c-0.2,0-0.5-0.1-1.1-0.2s-0.9-0.3-1.2-0.4c-0.4-0.1-0.8-0.5-1.3-1c-0.5-0.5-0.8-1-1.1-1.4
		c-0.2-0.3-0.4-0.6-0.6-1.1c-0.2-0.5-0.4-0.9-0.5-1.3c-0.2-0.9-0.3-1.9-0.3-3.1s0-2.3,0.1-3.5c0.1-0.4,0.1-1,0.2-1.6
		c0.1-0.6,0.1-1,0-1.1c-0.1,0-0.2,0.2-0.5,0.6c-0.3,0.4-0.4,0.6-0.5,0.7c-0.5,0.7-1,1.6-1.6,2.8c-0.6,1.1-1.2,2.4-1.9,3.9
		c-0.5,1-0.9,1.9-1.3,2.8s-0.9,1.7-1.5,2.6c-0.2,0.4-0.5,0.6-0.7,0.8c-0.2,0.1-0.6,0.2-1.1,0.3l-0.7,0.1c-0.2,0.1-0.5,0.1-0.8,0
		c-0.4,0-0.7,0-0.9,0c-0.2,0-0.4-0.2-0.8-0.6c-0.4-0.4-0.7-0.8-1.1-1.3s-0.6-0.9-0.9-1.4c-0.3-0.4-0.4-0.7-0.4-0.7
		c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.3,0-0.6c0-1.1,0.4-2.6,1.1-4.2c0.7-1.7,1.6-3.4,2.7-5.1s2.2-3.4,3.4-5s2.3-2.9,3.3-3.8
		c0.1-0.1,0.5-0.4,1.2-0.9c0.7-0.4,1.4-0.7,2.1-0.7c0.2,0,0.4,0,0.7,0.1c0.3,0.1,0.6,0.1,0.8,0.2c0.2,0.1,0.6,0.3,1.1,0.6
		c0.5,0.3,0.8,0.5,0.9,0.7v1.2c0.4,0.1,0.9,0.4,1.5,0.7c0.6,0.3,1.1,0.5,1.3,0.5h0.4c0,0,0.1,0,0.2-0.1l0.4,0.3
		c0.2,0.1,0.5,0.3,0.7,0.7c0.2,0.4,0.3,0.6,0.3,0.8c0,0.2-0.2,0.8-0.6,1.8c-0.4,1-0.8,2-1,3.1c-0.1,0.5-0.3,1.2-0.4,1.9
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.1,0.7-0.2,1.3-0.3,1.8C161,35.6,161,36,161,36l-0.1,4.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0
		c0.7,0,1.4-0.2,2-0.4c0.7-0.2,1.3-0.6,2-1s1.5-0.9,2.2-1.3s1.7-1,2.6-1.5c0,0.1,0,0.2,0.1,0.2S171.3,36.3,171.2,36.4 M147.3,36.5
		l-0.1,0.6c0.6-0.6,1.3-1.3,2.1-2.2c0.8-0.9,1.4-1.7,2-2.4s1.1-1.3,1.5-2s0.9-1.3,1.4-2.1c0.4-0.7,0.9-1.5,1.3-2.4
		c0.4-0.9,0.9-1.9,1.4-3c-1.4,1.1-2.6,2.1-3.6,3.2c-1,1.1-1.8,2.2-2.6,3.3c-0.7,1.1-1.4,2.3-1.9,3.4
		C148.3,34.2,147.8,35.4,147.3,36.5"
        />
        <path
          d="M176,23.1c0,0.2,0,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.2,0.6-0.4,1.2-0.6,1.7c-0.2,0.5-0.4,1.1-0.6,1.6
		c-0.2,0.6-0.4,1.1-0.5,1.8c-0.2,0.6-0.3,1.4-0.4,2.2c-0.1,0.6-0.3,1.4-0.4,2.2s-0.3,1.6-0.4,2.4c-0.1,0.8-0.2,1.4-0.3,2
		c-0.1,0.6-0.1,0.9-0.1,1.1c0,0.2,0.1,0.7,0.2,1.3s0.4,1.3,0.6,1.8c0.4,0.2,0.7,0.3,1.1,0.2c0,0.1,0,0.2,0.1,0.5s0.1,0.4,0,0.5
		c-0.1,0.1-0.3,0.3-0.6,0.6s-0.5,0.4-0.8,0.4s-0.6-0.1-1-0.4c-0.4-0.3-0.8-0.6-1-0.8c-0.5-0.5-0.9-1.1-1.2-1.9
		c-0.4-0.8-0.7-1.6-1-2.4c-0.3-0.8-0.5-1.6-0.6-2.5c-0.2-0.8-0.2-1.5-0.2-2.1c-0.1-2.7,0.3-5.4,1.1-8.1c0.8-2.6,1.9-5.2,3.2-7.8h0.7
		c1.1,0.8,1.9,1.6,2.2,2.4C175.9,21.8,176,22.5,176,23.1 M179.7,13.1c0,0.9-0.2,1.5-0.6,1.7s-1.1,0.4-1.8,0.4
		c-1.3,0-2.6-0.8-3.9-2.5c-0.3-0.3-0.4-0.7-0.5-1c-0.1-0.4-0.1-0.7-0.1-1.1s-0.1-0.8-0.1-1.2c-0.1-0.4-0.2-0.8-0.4-1.1l0.4-0.4
		c0.3,0,0.7,0.2,1.1,0.4c0.4,0.2,0.9,0.5,1.3,0.8c0.4,0.3,0.8,0.6,1.1,0.8s0.6,0.4,0.7,0.5c0.2,0.1,0.5,0.1,0.9,0.1s0.7,0.1,0.9,0.3
		c0.2,0.3,0.5,0.7,0.8,1.2C179.6,12.5,179.7,12.9,179.7,13.1"
        />
        <path
          d="M21.1,13.8c1.7,0,3.2,0.6,4.4,1.8c1.2,1.2,1.8,2.7,1.8,4.4s-0.6,3.2-1.8,4.4c-0.4,0.4-0.8,0.7-1.3,1c0.4,0.3,0.9,0.6,1.3,1
		c1.2,1.2,1.8,2.7,1.8,4.4s-0.6,3.2-1.8,4.4c-1.2,1.2-2.7,1.8-4.4,1.8H10.7V13.8L21.1,13.8L21.1,13.8z M21.1,24.6
		c1.3,0,2.3-0.4,3.2-1.3c0.9-0.9,1.3-2,1.3-3.2s-0.4-2.4-1.3-3.2s-2-1.3-3.2-1.3h-8.8v9.2C12.3,24.6,21.1,24.6,21.1,24.6z
		 M21.1,26.3h-8.8v9.2h8.8c1.3,0,2.3-0.4,3.2-1.3c0.9-0.9,1.3-2,1.3-3.2s-0.4-2.4-1.3-3.2S22.4,26.3,21.1,26.3"
        />
        <path d="M42.4,37.1h-1.7v-2.2c-0.1,0.1-0.2,0.2-0.3,0.3c-1.3,1.3-2.9,1.9-4.7,1.9s-3.4-0.6-4.7-1.9c-1.3-1.3-1.9-2.9-1.9-4.7v-10
		h1.7v10c0,1.4,0.5,2.6,1.5,3.5s2.2,1.5,3.5,1.5s2.6-0.5,3.5-1.5s1.5-2.2,1.5-3.5v-10h1.7V37.1z" />
        <polygon points="51.2,37.1 44.1,20.4 45.9,20.4 52.2,35.1 58.4,20.4 60.3,20.4 50.2,43.8 48.4,43.8 	" />
        <rect x="9.6" y="41" width="50.6" height="1.1" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  width: 200px;
}
</style>
