<template>
  <Dialog
    header="Linie na mapě"
    v-model:visible="isDialogOpen"
    :modal="true"
    class="dialog"
    :closable="true"
    :dismissableMask="true"
    style="width: 95%; max-width: 1000px"
  >
    <Button
      label="Zrušit poslední bod"
      icon="pi pi-times"
      @click="removeLastPoint"
      class="p-button-secondary p-mb-2"
    />

    <div class="map-wrapper">
      <l-map :zoom="zoom" :center="center" @click="mapClicked">
        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LPolyline
          v-if="show && coordinates.length > 1"
          :lat-lngs="coordinates"
          color="#002059"
        />
        <l-circle-marker
          v-for="point in coordinates"
          :key="point"
          :lat-lng="point"
          :radius="5"
          color="#002059"
          :fill="true"
          :fillOpacity="0.7"
          fillColor="#9F1D37"
        />
      </l-map>
    </div>

    <template #footer>
      <Button
        label="Storno"
        icon="pi pi-times"
        @click="isDialogOpen = false"
        class="p-button-text"
      />
      <Button label="Uložit" icon="pi pi-check" @click="save" />
    </template>
  </Dialog>

  <div class="value" @click="isDialogOpen = true">
    <span v-if="coordinates.length > 1">
      Linie (počet bodů: {{ coordinates.length }})
    </span>
    <span v-else>
      <Button label="Zvolit" class="p-button-secondary" />
    </span>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import {
  LMap,
  LTileLayer,
  LCircleMarker,
  LTooltip,
  LPolyline,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  emits: ["line-picked"],
  props: {
    value: String,
    name: String,
  },
  data() {
    return {
      isDialogOpen: false,
      show: true,
      zoom: 13,
      coordinates: [],
      showMap: false
    };
  },
  computed: {
    center() {
      if (this.coordinates.length > 0) {
        return this.coordinates[0];
      } else {
        return ["49.0311586", "17.0781749"];
      }
    },
  },
  mounted() {
    if (this.value) {
      this.coordinates = JSON.parse(this.value);
      this.refreshLine();
    }
  },
  methods: {
    mapClicked(e) {
      if (e.latlng) {
        this.coordinates.push([e.latlng.lat, e.latlng.lng]);
        this.refreshLine();
      }
    },
    refreshLine() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 50);
    },
    removeLastPoint() {
      this.coordinates.pop();
      this.refreshLine();
    },
    save() {
      if (this.coordinates.length <= 1) {
        window.eventBus.emit("error-toast", "Je třeba vybrat alespoň dva body!");
      } else {
        this.isDialogOpen = false;

        this.$emit("line-picked", {
          name: this.name,
          value: JSON.stringify(this.coordinates),
        });
      }
    },
  },
  components: {
    Dialog,
    Button,
    LMap,
    LTileLayer,
    LPolyline,
    LCircleMarker,
    LTooltip,
  },
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  width: 100%;
  height: 500px;
  border-radius: 5px;
}
.leaflet-grab {
  cursor: crosshair;
}
</style>
