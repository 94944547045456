<template>
  <div class="item">
    <div class="item__label">Jméno:</div>
    <div class="item__value">
      {{ user.name }}
    </div>
  </div>

  <div class="item">
    <div class="item__label">E-mail:</div>
    <div class="item__value">
      {{ user.email }}
    </div>
  </div>

  <div class="item">
    <div class="item__label">Obec:</div>
    <div class="item__value" v-if="user.town">
      {{ user.town.name }}
    </div>
    <div class="item__value" v-else>Žádná přiřazená obec</div>
  </div>

  <div class="help">
    <div class="help__icon">
      <Icon name="circleQuestion" />
    </div>
    <p class="help__text">
      Potřebujete-li aktualizovat své údaje, kontaktuje prosím administrátory.
    </p>
  </div>
</template>

<script>
import Icon from '@/components/icons/Icon';

export default {
  data() {
    return {
      user: this.$store.state.userStore.user,
    };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  &__label {
    width: 20%;
    max-width: 100px;
    font-weight: 700;
  }
}
.help {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  &__icon {
    --size: 30px;
    max-width: var(--size);
    max-height: var(--size);
  }
}
</style>
