<template>
  <template v-if="!loading">
    <Form :fields="fields" :onSubmit="submit" />
  </template>
</template>

<script>
import { Homes } from '@/services/repositories/SaleHomes';
import Form from '@/components/form/Form';
import MainInfoFields from '../services/MainFields';

export default {
  props: {
    home: Object
  },
  data() {
    return {
      fields: MainInfoFields,
      loading: true
    };
  },
  mounted() {
    if (this.home) {
      this.fields.setValuesFromEntity(this.home);
    } else {
      this.fields.reset();
    }
    this.loading = false;
  },
  methods: {
    submit(values) {
      if (this.home) {
        values.id = this.home.id;
      }

      Homes.save(values).then(response => {
        if (response.result == 'ok') {
          window.eventBus.emit('success-toast', 'Apartmán uložen.');

          if (!this.home) {
            window.eventBus.emit('home-created', response.id);
          }
        } else {
          window.eventBus.emit('error-toast', 'Apartmán se nepodařilo uložit!');
        }
      });
    }
  },
  components: {
    Form
  }
};
</script>

<style lang="scss" scoped></style>
