<template>
  <Loading v-if="loading" text="Načítám apartmán..." />
  <template v-else>
    <div class="page-head">
      <h1 v-if="home">
        K prodeji:
        <span class="ft">
          {{ home.name.cs }}
        </span>
      </h1>

      <h1 v-if="!home">Nová nemovitost</h1>

      <div class="page-head__actions">
        <Button
          label="Zpět"
          @click="$router.push({ name: 'saleHomes-list' })"
          class="p-button-secondary"
          icon="pi pi-arrow-left"
        />
        <Button
          label="Smazat"
          @click="remove"
          v-if="home"
          class="p-button-secondary"
          icon="pi pi-trash"
        />
      </div>
    </div>

    <TabView class="p-tabview--no-bg" v-model:activeIndex="activeTab">
      <TabPanel header="Základní info">
        <div class="box">
          <MainInfoForm :home="home" />
        </div>
      </TabPanel>
      <TabPanel header="Galerie" :disabled="home ? false : true">
        <ImagesUpload :home="home" v-if="home" />
        <ImagesList :home="home" v-if="home" />
      </TabPanel>
    </TabView>
  </template>
</template>

<script>
import { Homes } from '@/services/repositories/SaleHomes';
import TabView from 'primevue/tabview';
import MainInfoForm from '../components/MainInfoForm';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import ImagesUpload from '../components/gallery/ImagesUpload.vue';
import ImagesList from '../components/gallery/ImagesList.vue';
import Loading from '@/components/Loading.vue';

export default {
  data() {
    return {
      home: null,
      loading: true,
      activeTab: 0,
      GALLERY_TAB: 1,
    };
  },
  mounted() {
    this.initData();

    window.eventBus.on('home-created', data => {
      this.initData(data);
    });

    window.eventBus.on('images-updated', () => {
      this.initData(this.home.id, this.GALLERY_TAB);
    });
  },
  unmounted() {
    window.eventBus.off('home-created');
    window.eventBus.off('images-updated');
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name) {
        this.initData();
      }
    }
  },
  methods: {
    initData(homeId, goToTab) {
      this.loading = true;
      homeId = homeId || this.$route.params.id;

      if (homeId) {
        Homes.getById(homeId).then(response => {
          this.home = response;
          this.loading = false;
          document.title = this.home.name.cs;

          if (goToTab) {
            this.activeTab = goToTab;
          }
        });
      } else {
        this.loading = false;
        this.home = null;
        document.title = 'Nový apartmán';
      }
    },
    remove() {
      this.$confirm.require({
        message: 'Opravdu chcete smazat apartmán ' + this.home.name.cs + '?',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary',
        acceptClass: 'p-button-primary',
        accept: () => {
          Homes.remove(this.home).then(() => {
            this.$router.push({ name: 'saleHomes-list' });
            window.eventBus.emit('success-toast', 'Apartmán ' + this.home.name.cs + ' byl odstraněn.');
          });
        }
      });
    }
  },
  components: {
    TabView,
    MainInfoForm,
    ImagesUpload,
    ImagesList,
    TabPanel,
    Loading,
    Button
  }
};
</script>

<style lang="scss" scoped></style>
