<template>
  <h2>Nahrát foto</h2>
  <div class="dropzone">
    <div class="dropzone__text">
      Do tohoto obdélníku přetáhněte fotky, které chcete nahrát na server. Fotka
      by neměla přesahovat rozměry 2500x2500 px.
    </div>
    <div class="dropzone__in-progress" v-if="inProgress">
      Nahrávám...
    </div>
    <div
      :class="['dropzone__area', active ? 'dropzone__area--active' : 'null']"
      @drop.prevent="onDrop"
      @dragenter.prevent="setActive"
      @dragover.prevent="setActive"
      @dragleave.prevent="setInactive"
    ></div>
  </div>
</template>

<script>
import { Gallery } from '@/services/repositories/SaleHomes';

export default {
  props: {
    home: {
      type: Object
    }
  },
  data() {
    return {
      active: false,
      inProgress: false
    };
  },
  methods: {
    onDrop(e) {
      const files = e.dataTransfer.files;
      this.inProgress = true;

      Gallery.upload(files, this.home.id).then(response => {
        response.images.forEach(file => {
          window.eventBus.emit('success-toast', 'Obrázek nahrán.');
        });

        this.inProgress = false;
        window.eventBus.emit('images-updated');
      });
    },
    setActive() {
      this.active = true;
    },
    setInactive() {
      this.active = false;
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.dropzone {
  border: 3px dotted var(--primary-color);
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  position: relative;
  background: #fff;
  &__text {
    width: 100%;
    max-width: 500px;
    text-align: center;
  }
  &__in-progress {
    border-radius: var(--radius);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__area {
    border-radius: var(--radius);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &--active {
      background: rgba(#ccc, 0.1);
    }
  }
}
</style>
