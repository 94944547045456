import { store } from '@/store/store.js';
import { API } from './API';

const INVALID_TOKEN = 1;
const BASE_URL = 'login/';
const PROFILE_BASE_URL = 'profile/';

const User = {
  authenticate,
  login,
  logout,
  isLoggedIn,
  sendPasswordRestoreEmail,
  checkPasswordRestoreHash,
  setNewPassword,
  changePassword,
  updateInfo,
  INVALID_TOKEN,
};



function login(token, user) {
  localStorage.setItem('token', token);
  store.commit('login', user);
  window.eventBus.emit('login');
}

async function authenticate(credentials) {
  if (credentials.email && credentials.password) {
    return API.get(
      BASE_URL +
        'authenticate/' +
        credentials.email +
        '/' +
        credentials.password,
      false
    );
  } else if (credentials.token) {
    return API.get(BASE_URL + 'authenticate/' + credentials.token, false);
  } else {
    return new Promise((resolve) => {
      resolve({
        result: 'failed',
      });
    });
  }
}

function isLoggedIn() {
  return store.getters.getUser.isLoggedIn;
}

function logout() {
  window.eventBus.emit('logout');
  localStorage.removeItem('token');
  store.commit('logout');
}

async function sendPasswordRestoreEmail(email) {
  var url = BASE_URL + 'send-password-restore-email/' + email;
  return API.get(url, false);
}

async function checkPasswordRestoreHash(hash) {
  var url = BASE_URL + 'check-password-restore-hash/' + hash;
  return API.get(url, false);
}

async function setNewPassword(newPassword, hash) {
  var url = BASE_URL + 'set-new-password/' + newPassword + '/' + hash;
  return API.get(url, false);
}

async function changePassword(newPassword) {
  var url = PROFILE_BASE_URL + 'change-password/' + newPassword;
  return API.get(url);
}

async function updateInfo(itemName, itemValue, accountId) {
  var url = PROFILE_BASE_URL + 'update-info';

  if (accountId) {
    url += '/' + parseInt(accountId);
  }

  let data = {};
  data[itemName] = itemValue;

  return API.post(url, data);
}

const Registration = {
  url: BASE_URL + 'registration/',

  create: async function (data) {
    return API.post(this.url + 'create', data, {}, false);
  },

  verifyEmail: async function (hash) {
    return API.get(this.url + 'verify/' + hash, false);
  },
};

export { User, Registration };
