<template>
  <div class="user-panel">
    <div class="overlay-trigger" @click="togglePanel">
      <div class="overlay-trigger__profile-picture">
        <div
          class="profile-picture profile-picture--fake"
          v-if="!user.profilePicture"
        >
          <Icon name="user" />
        </div>
      </div>
      <div class="overlay-trigger__name">
        {{ user.name }}
      </div>
      <div class="overlay-trigger__chevron">
        <Icon name="chevronDown" />
      </div>
    </div>

    <OverlayPanel ref="op">
      <UserMenu />
    </OverlayPanel>
  </div>
</template>

<script>
import Icon from '@/components/icons/Icon';
import OverlayPanel from 'primevue/overlaypanel';
import UserMenu from './UserMenu.vue';

export default {
  data() {
    return {
      user: this.$store.state.userStore.user,
    };
  },
  methods: {
    togglePanel(event) {
      this.$refs.op.toggle(event);
    },
  },
  components: {
    Icon,
    OverlayPanel,
    UserMenu,
  },
};
</script>

<style lang="scss" scoped>
.user-panel {
  padding-top: 15px;
  .overlay-trigger {
    --iconSize: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    &__chevron {
      max-width: var(--iconSize);
      max-height: var(--iconSize);
    }
    &__name {
      line-height: 1;
    }
    &:hover {
      color: var(--secondary-color);
    }
  }
  .profile-picture {
    &--fake {
      --size: 25px;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      background: var(--primary-color);
      color: #fff;
      padding: 6px;
    }
  }
}
</style>
