export default [
  {
    text: 'K pronájmu',
    to: 'rentalHomes-list',
    icon: 'houseBuilding',
  },
  {
    text: 'K prodeji',
    to: 'saleHomes-list',
    icon: 'houseBuilding',
  },
  {
    text: 'Uživatelé',
    to: 'accounts-list',
    icon: 'users',
  }
];
