<template>
  <div class="icon" v-html="svg" />
</template>

<script>
import { Icons } from './icons';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      svg: '',
    };
  },
  created() {
    if (typeof Icons[this.name] == 'undefined') {
      console.error('Icon named ' + this.name + ' not found in icons.js!');
    }

    this.svg = Icons[this.name];
  },
  watch: {
    name() {
      this.svg = Icons[this.name];
    },
  },
};
</script>

<style lang="scss" scoped></style>
