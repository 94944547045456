<template>
  <div class="public-page">
    <div :class="[ 'public-page__bg', isBgLoaded ? 'public-page__bg--loaded' : null ]">
      <img :src="publicPath + '/images/login-bg.jpg'" @load="isBgLoaded = true" />
    </div>
    <Logo />
    <div class="public-page__content-wrapper">
      <router-view name="mainContent" />
    </div>
  </div>
</template>

<script>
import Logo from './Logo';

export default {
  data() {
    return {
      isBgLoaded: false,
      publicPath: process.env.BASE_URL
    }
  },
  components: {
    Logo,
  },
};
</script>

<style lang="scss">
.public-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &:before {
      content: '';
      background: rgba(#000, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:after {
      content: '';
      background: #000;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity .5s ease;
    }
    &--loaded:after {
      opacity: 0;
    }
  }
  &__content-wrapper {
    position: relative;
    width: 100%;
    max-width: 500px;
    background: #fff;
    border-radius: 40px;
    padding: 60px 60px;
  }
  h1 {
    color: var(--primary-color);
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .logo {
    position: relative;
    svg {
      display: block;
      margin: 0 auto 80px auto;
      * {
        fill: #fff;
      }
    }
  }
  .message {
    line-height: 1.25;
  }
  .bottom-links {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .public-field-wrapper {
    width: 100%;
    max-width: 300px;
    margin-bottom: 25px;
  }
}
</style>
