import List from '../views/List';
import Detail from '../views/Detail';

const NAME_PREFIX = 'rentalHomes-';
const BASE_URL = '/domy-k-pronajmu';

export default [
  {
    name: NAME_PREFIX + 'list',
    path: BASE_URL,
    components: { mainContent: List },
    meta: { title: 'Domy k pronájmu' }
  },
  {
    name: NAME_PREFIX + 'new',
    path: BASE_URL + '/novy',
    components: { mainContent: Detail },
    meta: { title: 'Nový dům' }
  },
  {
    name: NAME_PREFIX + 'detail',
    path: BASE_URL + '/:id',
    components: { mainContent: Detail }
  }
];
