<template>
  <div :class="['sidebar', shrinked ? 'sidebar--shrinked' : '']">
    <router-link :to="{ name: 'dashboard' }" class="head">
      <div class="head__logo">
        <Logo />
      </div>
      <div class="head__text">
        {{ company }}
      </div>
    </router-link>
    <MainMenu />
  </div>
</template>

<script>
import MainMenu from './MainMenu';
import Logo from './Logo';
import config from '@/config.js';

export default {
  data() {
    return {
      shrinked: false,
      company: config.company.name,
    };
  },
  watch: {
    $route() {
      this.shrinked = true;
      setTimeout(() => {
        this.shrinked = false;
      }, 300);
    },
  },
  components: {
    MainMenu,
    Logo
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  width: var(--sidebar-width);
  transition: width 0.3s ease;
  background: var(--primary-color);
  z-index: 999;
  padding-bottom: 100px;
  &:hover {
    width: var(--sidebar-open-width);
    max-width: 100%;
    .logo__extends {
      max-width: var(--sidebar-open-width);
    }
  }
  .head {
    display: flex;
    align-items: center;
    width: 100%;
    background: var(--primary-color);
    overflow: hidden;
    &__logo {
      width: var(--sidebar-width);
      padding: 15px 15px 5px 15px;
      flex-shrink: 0;
    }
    &__text {
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      white-space: nowrap;
    }
    &:hover {
      background: var(--secondary-color);
    }
  }
  &--shrinked {
    width: var(--sidebar-width) !important;
    .head {
    }
  }
}
</style>
