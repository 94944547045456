import { Rules } from '@/services/form/Rules';
import { FieldTypes } from '@/services/form/FieldTypes';

export default {
  fields: {
    name_cs: {
      name: 'name_cs',
      label: 'Název CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true,
      rules: [
        {
          type: Rules.required,
          message: 'Zvolte český název.'
        },
        {
          type: Rules.maxLength,
          length: 250,
          message: 'Název nesmí být delší než 250 znaků.'
        }
      ]
    },
    name_en: {
      name: 'name_en',
      label: 'Název EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true,
      rules: [
        {
          type: Rules.required,
          message: 'Zvolte anglický název.'
        }
      ]
    },
    city_cs: {
      name: 'city_cs',
      label: 'Lokalita CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    city_en: {
      name: 'city_en',
      label: 'Lokalita EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    price_czk: {
      name: 'price_czk',
      label: 'Cena CZK',
      value: null,
      defaultValue: null,
      type: FieldTypes.money,
      currency: 'CZK'
    },
    price_usd: {
      name: 'price_usd',
      label: 'Cena USD',
      value: null,
      defaultValue: null,
      type: FieldTypes.money,
      currency: 'USD'
    },
    price_eur: {
      name: 'price_eur',
      label: 'Cena EUR',
      value: null,
      defaultValue: null,
      type: FieldTypes.money,
      currency: 'EUR'
    },
    price_aed: {
      name: 'price_aed',
      label: 'Cena AED',
      value: null,
      defaultValue: null,
      type: FieldTypes.money,
      currency: 'AED'
    },
    price_is_from: {
      name: 'price_is_from',
      label: 'Cena od?',
      value: true,
      defaultValue: true,
      type: FieldTypes.checkbox
    },
    development_type_cs: {
      name: 'development_type_cs',
      label: 'Dispozice CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      rules: [
        {
          type: Rules.maxLength,
          length: 250,
          message: 'Dispozice CS nesmí být delší než 250 znaků.'
        }
      ]
    },
    development_type_en: {
      name: 'development_type_en',
      label: 'Dispozice EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      rules: [
        {
          type: Rules.maxLength,
          length: 250,
          message: 'Dispozice EN nesmí být delší než 250 znaků.'
        }
      ]
    },
    completion_date: {
      name: 'completion_date',
      label: 'Termín dokončení',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      rules: [
        {
          type: Rules.maxLength,
          length: 250,
          message: 'Dispozice EN nesmí být delší než 250 znaků.'
        }
      ]
    },
    developer: {
      name: 'developer',
      label: 'Developer',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      rules: [
        {
          type: Rules.maxLength,
          length: 250,
          message: 'Developer nesmí být delší než 250 znaků.'
        }
      ]
    },
    description_cs: {
      name: 'description_cs',
      label: 'Popis CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.editor,
      fullWidth: true
    },
    description_en: {
      name: 'description_en',
      label: 'Popis EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.editor,
      fullWidth: true
    },
    video: {
      name: 'video',
      label: 'YouTube',
      value: '',
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true
    },
    gps: {
      name: 'gps',
      label: 'Poloha',
      value: '',
      defaultValue: '',
      type: FieldTypes.point,
    },
    submit: {
      text: 'Uložit',
      name: 'submit',
      type: FieldTypes.submit
    }
  },
  get() {
    return this.fields;
  },
  setValue(field, value) {
    this.fields[field].value = value;
  },
  setValuesFromEntity(home) {
    this.setValue('name_cs', home.name.cs);
    this.setValue('name_en', home.name.en);
    this.setValue('city_cs', home.city.cs);
    this.setValue('city_en', home.city.en);
    this.setValue('price_czk', home.priceCzk);
    this.setValue('price_usd', home.priceUsd);
    this.setValue('price_eur', home.priceEur);
    this.setValue('price_aed', home.priceAed);
    this.setValue('price_is_from', home.priceIsFrom == 1);
    this.setValue('description_cs', home.description.cs);
    this.setValue('description_en', home.description.en);
    this.setValue('development_type_cs', home.developmentType.cs);
    this.setValue('development_type_en', home.developmentType.en);
    this.setValue('completion_date', home.completionDate);
    this.setValue('developer', home.developer);
    this.setValue('video', home.video);
    this.setValue('gps', home.gps);
  },
  getValues() {
    let values = {};

    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.value === 'undefined') {
        continue;
      }

      values[field.name] = field.value;
    }

    return values;
  },
  reset() {
    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.defaultValue === 'undefined') {
        continue;
      }

      field.value = field.defaultValue;
    }
  }
};
