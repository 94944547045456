import { Rules } from '@/services/form/Rules';
import { FieldTypes } from '@/services/form/FieldTypes';

export default {
  fields: {
    name_cs: {
      name: 'name_cs',
      label: 'Název CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true,
      rules: [
        {
          type: Rules.required,
          message: 'Zvolte český název.'
        }
      ]
    },
    name_en: {
      name: 'name_en',
      label: 'Název EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true,
      rules: [
        {
          type: Rules.required,
          message: 'Zvolte anglický název.'
        }
      ]
    },
    city_cs: {
      name: 'city_cs',
      label: 'Lokalita CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    city_en: {
      name: 'city_en',
      label: 'Lokalita EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    price_czk: {
      name: 'price_czk',
      label: 'Cena CZK',
      value: null,
      defaultValue: null,
      type: FieldTypes.money,
      currency: 'CZK'
    },
    price_usd: {
      name: 'price_usd',
      label: 'Cena USD',
      value: null,
      defaultValue: null,
      type: FieldTypes.money,
      currency: 'USD'
    },
    price_eur: {
      name: 'price_eur',
      label: 'Cena EUR',
      value: null,
      defaultValue: null,
      type: FieldTypes.money,
      currency: 'EUR'
    },
    price_aed: {
      name: 'price_aed',
      label: 'Cena AED',
      value: null,
      defaultValue: null,
      type: FieldTypes.money,
      currency: 'AED'
    },
    price_is_from: {
      name: 'price_is_from',
      label: 'Cena od?',
      value: true,
      defaultValue: true,
      type: FieldTypes.checkbox
    },
    rooms_cs: {
      name: 'rooms_cs',
      label: 'Dispozice CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    rooms_en: {
      name: 'rooms_en',
      label: 'Dispozice EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    people_count_cs: {
      name: 'people_count_cs',
      label: 'Osoby CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    people_count_en: {
      name: 'people_count_en',
      label: 'Osoby EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    bathroom_en: {
      name: 'bathroom_en',
      label: 'Koupelna EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    bathroom_cs: {
      name: 'bathroom_cs',
      label: 'Koupelna CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.text
    },
    area: {
      name: 'area',
      label: 'Výměra',
      value: null,
      defaultValue: null,
      type: FieldTypes.number
    },
    description_cs: {
      name: 'description_cs',
      label: 'Popis CS',
      value: null,
      defaultValue: '',
      type: FieldTypes.editor,
      fullWidth: true
    },
    description_en: {
      name: 'description_en',
      label: 'Popis EN',
      value: null,
      defaultValue: '',
      type: FieldTypes.editor,
      fullWidth: true
    },
    wifi: {
      name: 'wifi',
      label: 'Wifi?',
      value: true,
      defaultValue: true,
      type: FieldTypes.checkbox
    },
    parking: {
      name: 'parking',
      label: 'Parkování?',
      value: true,
      defaultValue: true,
      type: FieldTypes.checkbox
    },
    pool: {
      name: 'pool',
      label: 'Bazén?',
      value: true,
      defaultValue: true,
      type: FieldTypes.checkbox
    },
    kitchen: {
      name: 'kitchen',
      label: 'Kuchyně?',
      value: true,
      defaultValue: true,
      type: FieldTypes.checkbox
    },
    beach: {
      name: 'beach',
      label: 'Pláž?',
      value: true,
      defaultValue: true,
      type: FieldTypes.checkbox
    },
    video: {
      name: 'video',
      label: 'YouTube',
      value: '',
      defaultValue: '',
      type: FieldTypes.text,
      fullWidth: true
    },
    gps: {
      name: 'gps',
      label: 'Poloha',
      value: '',
      defaultValue: '',
      type: FieldTypes.point,
    },
    submit: {
      text: 'Uložit',
      name: 'submit',
      type: FieldTypes.submit
    }
  },
  get() {
    return this.fields;
  },
  setValue(field, value) {
    this.fields[field].value = value;
  },
  setValuesFromEntity(home) {
    this.setValue('name_cs', home.name.cs);
    this.setValue('name_en', home.name.en);
    this.setValue('city_cs', home.city.cs);
    this.setValue('city_en', home.city.en);
    this.setValue('price_czk', home.priceCzk);
    this.setValue('price_usd', home.priceUsd);
    this.setValue('price_eur', home.priceEur);
    this.setValue('price_aed', home.priceAed);
    this.setValue('price_is_from', home.priceIsFrom == 1);
    this.setValue('rooms_cs', home.rooms.cs);
    this.setValue('rooms_en', home.rooms.en);
    this.setValue('people_count_cs', home.peopleCount.cs);
    this.setValue('people_count_en', home.peopleCount.en);
    this.setValue('bathroom_cs', home.bathroom.cs);
    this.setValue('bathroom_en', home.bathroom.en);
    this.setValue('area', home.area);
    this.setValue('description_cs', home.description.cs);
    this.setValue('description_en', home.description.en);
    this.setValue('wifi', home.wifi == 1);
    this.setValue('parking', home.parking == 1);
    this.setValue('pool', home.pool == 1);
    this.setValue('kitchen', home.kitchen == 1);
    this.setValue('beach', home.beach == 1);
    this.setValue('video', home.video);
    this.setValue('gps', home.gps);
  },
  getValues() {
    let values = {};

    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.value === 'undefined') {
        continue;
      }

      values[field.name] = field.value;
    }

    return values;
  },
  reset() {
    for (var name in this.fields) {
      let field = this.fields[name];

      if (field.defaultValue === 'undefined') {
        continue;
      }

      field.value = field.defaultValue;
    }
  }
};
