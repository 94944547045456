<template>
  <Dialog
    header="Výběr ikony"
    v-model:visible="isDialogOpen"
    :modal="true"
    class="dialog"
    :closable="true"
    :dismissableMask="true"
  >
    <div class="icons__grid">
      <div
        @click="select(name)"
        :class="[
          'icon-wrapper',
          name == selectedIcon ? 'icon-wrapper--active' : null,
        ]"
        v-for="(svg, name) in icons"
        :key="name"
      >
        <Icon :name="name" />
      </div>
    </div>

    <template #footer>
      <Button
        label="Storno"
        icon="pi pi-times"
        @click="isDialogOpen = false"
        class="p-button-text"
      />
      <Button label="Vybrat" icon="pi pi-check" @click="save" />
    </template>
  </Dialog>

  <div class="selected-icon" @click="isDialogOpen = true">
    <Icon :name="selectedIcon" />
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { Icons } from '@/components/icons/icons.js';
import Icon from '@/components/icons/Icon';

export default {
  emits: ['icon-selected'],
  props: {
    default: {
      type: String,
    },
    value: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      isDialogOpen: false,
      icons: Icons,
      selectedIcon: 'star',
    };
  },
  mounted() {
    if (this.default) {
      this.selectedIcon = this.default;
    }

    if (this.value) {
      this.selectedIcon = this.value;
    }
  },
  methods: {
    select(name) {
      this.selectedIcon = name;
    },
    save() {
      if (!this.selectedIcon) {
        window.eventBus.emit('error-toast', 'Vyberte ikonu!');
      } else {
        this.isDialogOpen = false;

        this.$emit('icon-selected', {
          fieldName: this.name,
          icon: this.selectedIcon,
        });
      }
    },
  },
  components: {
    Dialog,
    Icon,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.icons__grid {
  display: grid;
  grid-template-columns: repeat(10, 40px);
  gap: 20px;
  margin: 0 auto;
}
.icon-wrapper {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  &:hover,
  &--active {
    border-color: var(--primary-color);
  }
}
.selected-icon {
  --size: 40px;
  padding: 3px;
  width: var(--size);
  height: var(--size);
  color: var(--primary-color);
  border: 2px solid #fff;
  border-radius: var(--radius);
  cursor: pointer;
  &:hover {
    border-color: var(--primary-color);
  }
}
</style>
