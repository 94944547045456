<template>
  <h2>Nahrané fotky</h2>

  <p v-if="home.images.length == 0">Žádné nahrané fotky.</p>

  <Draggable
    v-model="images"
    @start="drag = true"
    @end="drag = false"
    item-key="id"
    v-bind="dragOptions"
    @update="saveOrder(images)"
  >
    <template #item="{element}">
      <div class="image box">
        <div class="image__handle">
          <Icon name="arrowsUpDown" />
        </div>
        <div class="image__content">
          <div class="image__thumb-wrapper">
            <Image
              :src="element.file.thumb + '?v=' + (new Date).getTime()"
              imageClass="gallery-image__thumb"
              preview
            />
          </div>
          <div class="image__actions">
            <button class="p-button p-button-text p-button-icon-only p-button-custom-icon" @click="rotate(element, 90)">
              <Icon name="arrowLeftRotate" />
            </button>
            <button class="p-button p-button-text p-button-icon-only p-button-custom-icon" @click="rotate(element, -90)">
              <Icon name="rotateForward" />
            </button>
            <Button
              @click="remove(element)"
              class="p-button-text"
              icon="pi pi-trash"
            />
          </div>
        </div>
      </div>
    </template>
  </Draggable>
</template>

<script>
import Button from 'primevue/button';
import { Gallery } from '@/services/repositories/RentalHomes';
import Icon from '@/components/icons/Icon';
import Image from 'primevue/image';
import Draggable from 'vuedraggable';

export default {
  props: {
    home: Object
  },
  data() {
    return {
      images: [],
      drag: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    };
  },
  mounted() {
    this.images = JSON.parse(JSON.stringify(this.home.images));
  },
  methods: {
    saveOrder(list) {
      var order = [];

      for (var i = 0; i < list.length; i++) {
        order.push({
          id: list[i].id,
          order: i
        });
      }

      Gallery.saveOrder(order).then(() => {
        window.eventBus.emit('success-toast', 'Pořadí aktualizováno.');
      });
    },
    remove(image) {
      this.$confirm.require({
        message: 'Opravdu chcete smazat tento obrázek?',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary',
        acceptClass: 'p-button-primary',
        accept: () => {
          Gallery.remove(image).then(() => {
            window.eventBus.emit('success-toast', 'Obrázek byl odstraněn.');
            window.eventBus.emit('images-updated');
          });
        }
      });
    },
    rotate(image, angle) {
      window.eventBus.emit('show-overlay-message', { text: 'Upravuji obrázek' });

      Gallery.rotate(image, angle).then(() => {
        window.eventBus.emit('success-toast', 'Obrázek aktualizován.');
        window.eventBus.emit('images-updated');
        window.eventBus.emit('hide-overlay-message');
      });
    }
  },
  components: {
    Button,
    Draggable,
    Icon,
    Image
  }
};
</script>

<style lang="scss">
.image__thumb-wrapper {
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
  span {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
.images {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.image {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: var(--gap);
  &__handle {
    --size: 20px;
    width: var(--size);
    height: var(--size);
    cursor: grab;
    color: var(--primary-color);
    &:hover {
      color: var(--secondary-color);
    }
  }
  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &__thumb-wrapper {
    width: 100px;
    height: 50px;
  }
  &__actions {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}
</style>
