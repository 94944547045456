import { API } from '@/services/API';

const BASE_URL = 'rental-homes/';

const Homes = {
  getAll: async function() {
    const url = BASE_URL + 'get-all';
    return API.get(url);
  },

  getById: async function(id) {
    return API.get(BASE_URL + 'get-by-id/' + parseInt(id));
  },

  remove: async function(home) {
    return API.get(BASE_URL + 'remove/' + parseInt(home.id));
  },

  save: async function(data) {
    return API.post(BASE_URL + 'save', data);
  }
};

const Gallery = {
  url: BASE_URL + 'gallery/',

  upload: async function(files, homeId) {
    let form = new FormData();

    files.forEach(file => {
      form.append('images[]', file);
    });

    form.append('homeId', parseInt(homeId));

    return API.post(this.url + 'upload', form);
  },

  remove: async function(image) {
    return API.get(this.url + 'remove/' + parseInt(image.id));
  },

  saveOrder: async function(order) {
    return API.post(this.url + 'save-order', order);
  },

  rotate: async function(image, angle) {
    return API.get(this.url + 'rotate/' + parseInt(image.id) + '/' + (parseInt(angle) + 360));
  }
};

export { Homes, Gallery };
